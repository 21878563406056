import React from "react";
import { Row, Col, Card, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

function TreeCard(props) {
    const trimmedDescription = props.description.substring(0, 300) + "... ";
    return (
        <Card className="card-plain card-blog">
            <Row>
                <Col md="4">
                    <div className="card-image">
                        <Link to={props.to}>
                            <img
                                alt="..."
                                className="img img-raised rounded"
                                src={props.src}
                            ></img>
                        </Link>
                    </div>
                </Col>
                <Col md="8">
                    <CardTitle tag="h3">
                        <Link to={props.to}>
                            {props.title}
                        </Link>
                    </CardTitle>
                    <p className="card-description">
                        {trimmedDescription}
                        <Link to={props.to}>
                            Learn More
                        </Link>
                    </p>
                </Col>
            </Row>
        </Card>
    )
}

export default TreeCard;