import React from "react";
import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["006", "007", "008", "009", "010"];

function getSrc(imgNumber) {
  return (`https://d11evpdhimy96f.cloudfront.net/black-hills-spruce/IMG_${imgNumber}.JPG`);
}

function getDescription() {
  return (<p>Black Hills Spruce are another ornamental evergreen that add value, privacy and natural beauty to your landscape. These Spruce are narrower, denser and darker green in color than the Colorado Blue, but still share all the same great qualities such as being fast growing, long lived and also resistant to wind, drought and extreme cold spells. They are common in parks and residential areas and closely resemble a Christmas tree.</p>);
}

function getSizes() {
  return (
    <p>
      We offer Black Hills Spruce that are planted in 5 gallon pots, which range in size from approximately 2 to 2.5 feet. These are ready for pickup at our nursery.
    </p>
  )
}

const items = imgNumbers.map(i => {
  return {
    src: getSrc(i),
    altText: "",
    caption: ""
  }
});

function BlackHillsSpruce() {
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <WhiteNavbar />
      <div className="wrapper">
        <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
          <TreeDetails items={items} name="Black Hills Spruce" price="$44 per tree (5 gal potted type)" description={getDescription()} sizes={getSizes()} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default BlackHillsSpruce;
