import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/nectarine-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our collection of Nectarine trees are some of the most recognizable and beloved kinds available. Independence Nectarines are a beautiful bright red with yellow freestone fruit. Always a top finisher in the taste test, and a very reliable producer, they are a classic favorite. The Red Gold is a large, freestone, sunny-golden fleshed nectarine and has the perfect combination of sweetness and tart flavor. The skin has a beautiful red blush that covers a firm, juicy flesh. In the springtime, both types are adorned with dazzling pinkish white flowers. Both are widely adaptable and are low maintenance trees. Nectarines grow best in a full sun location.
            </p>
            <p>
                Types of Nectarine Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Red Gold</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 7.5 and 8.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function NectarineTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Nectarine Trees"
                        price="$85* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default NectarineTrees;
