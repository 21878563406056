import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    DropdownMenu,
    DropdownItem
} from "reactstrap";

function AvailableTreesDropdown() {
    return (
        <>
            <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                <DropdownItem to="/available-trees" tag={Link}>
                    All Available Trees
                </DropdownItem>
                <DropdownItem to="/colorado-blue-spruce" tag={Link}>
                    Colorado Blue Spruce
                </DropdownItem>
                <DropdownItem to="/black-hills-spruce" tag={Link}>
                    Black Hills Spruce
                </DropdownItem>
                <DropdownItem to="/norway-spruce" tag={Link}>
                    Norway Spruce
                </DropdownItem>
            </DropdownMenu>
        </>
    )
};
export default AvailableTreesDropdown;