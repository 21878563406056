import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/pear-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our selection of Pears are some of the tastiest and most sought after available. Bartlett pears are known as the “canning pear” because they hold their shape and have a distinct flavor and sweetness when preserved. Their flesh is aromatic, moist, cream-colored to ivory. When mature but not fully ripe, Bartlett pears are crunchy, tart, and slightly gritty, but when fully ripe, they develop a juicy, smooth, buttery texture with a sweet flavor. Chefs and home cooks enjoy the firm quality of the pear, using it for baking desserts, such as crisps and pies. Anjou pears have a skin that is bright green, smooth, firm, and is covered in lenticels or pores and some rose blush on the side most exposed to the sun. The flesh is white to cream-colored, dense, and buttery with a slightly gritty texture. When ripe, Anjou pears are juicy and soft with subtle, sweet flavors. These trees have stunning spring blossoms which then yield ripe fruit in late Summer. They enjoy plenty of sunlight and are fast growing.
            </p>
            <p>
                Types of Pear Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Anjou</li>
                    <li>Bartlett</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 8 and 9.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function PearTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Pear Trees"
                        price="$85* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default PearTrees;
