/*eslint-disable*/
import React from "react";

// reactstrap testingponents
import { Container, Row, Col } from "reactstrap";
import TreeCard from "./TreeCard";

function AllTrees() {
  return (
    <>
      <div className="blogs-3">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h2 className="title">Our Available Trees</h2>
              <TreeCard
                title="Colorado Blue Spruce"
                to="/colorado-blue-spruce"
                src="https://d11evpdhimy96f.cloudfront.net/colorado-blue-spruce/IMG_001.JPG"
                description="With silvery blue-green tones and colors all year round, a sleek pyramidal shape and no-nonsense care, the Colorado Blue Spruce tree checks all the boxes of a landscape must-have and will impress no matter where it's planted. They are resistant to wind and can tolerate dry periods well, as they boast a wider deeper root system than most Spruce. Colorado Blues do best in sunny locations, and can grow 12 to 18 inches per year. Whether you’re looking to add value to your landscape, an all-natural privacy screen or a windbreak, these Spruce are an ideal choice."
              />
              <TreeCard
                title="Black Hills Spruce"
                to="/black-hills-spruce"
                src="https://d11evpdhimy96f.cloudfront.net/black-hills-spruce/IMG_006.JPG"
                description="Black Hills Spruce are another ornamental evergreen that add value, privacy and natural beauty to your landscape. These Spruce are narrower, denser and darker green in color than the Colorado Blue, but still share all the same great qualities such as being fast growing, long lived and also resistant to wind, drought and extreme cold spells. They are common in parks and residential areas and closely resemble a Christmas tree."
              />
              <TreeCard
                title="Norway Spruce"
                to="/norway-spruce"
                src="https://d11evpdhimy96f.cloudfront.net/norway-spruce/IMG_001.JPG"
                description="The fastest growing of all Spruce trees, the Norway Spruce is another excellent choice for your landscaping. This pyramidal conifer has large weeping branches with dark green foliage, and can quickly reach taller heights making it an ideal choice for a wind break, your yard or anywhere on your property that you're looking to provide some all natural privacy. These types of Spruce also thrive in cold climates and are long lived, plus resistant to wind and drought conditions."
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AllTrees;