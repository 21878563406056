import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/Footer.js";
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';

const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 40.885934, lng: -98.098803 }}
      defaultOptions={{
        scrollwheel: false
      }}
    >
      <Marker position={{ lat: 40.885934, lng: -98.098803 }} />
    </GoogleMap>
  ))
);

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);  
  const [buttonText, setButtonText] = React.useState("Contact Us");

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {

      document.body.classList.remove("sidebar-collapse");
    };
  });

  const submitForm = (ev) => {
    setDisableSubmit(true);
    ev.preventDefault();
    ReactGA.event({
      category: 'Contact',
      action: 'Submit Button Clicked'
    });
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {        
        setButtonText("Thank You!");
        ReactGA.event({
          category: 'Contact',
          action: 'Submit Button Success'
        });
      } else {        
        setDisableSubmit(false);
        ReactGA.event({
          category: 'Contact',
          action: 'Submit Button Error'
        });
      }
    };    
     xhr.send(data);
  }  

  const logPhoneLinkClick = () => {    
    ReactGA.event({
      category: 'Contact',
      action: 'Phone Link Clicked'
    });
  }

  return (
    <>
      <Helmet>
        <title>Luthy's Tree Farm - Contact Us</title>
        <meta name="description" content="Contact us with any questions about trees or tree services." />
      </Helmet>
      <ScrollTransparentNavbar scrollThreshold={0} alwaysShowBackground />
      <div className="wrapper">
        <ContactUsHeader />
        <div className="main">
          <div className="contact-content">
            <Container>              
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <h2 className="title">Have a question? Send us a message.</h2>
                  <p className="description">
                    You can contact us with anything related to our trees or services.
                    We'll get in touch with you as soon as possible. <br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" method="post" role="form" action="https://formspree.io/tylercorradi@outlook.com" onSubmit={submitForm}>
                    <label>Your name</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="Your Name..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        required={true}
                        name="name"
                        id="name"
                      ></Input>
                    </InputGroup>
                    <label>Email address</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        name="_replyto"
                        id="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        required={true}
                      ></Input>
                    </InputGroup>
                    <label>Phone</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons tech_mobile"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="number"
                        placeholder="Number Here..."
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>Your message</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <input type="hidden" name="_cc" value="gluthy@hamilton.net" />
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        color="info"
                        defaultValue="Contact Us"
                        type="submit"
                        disabled={disableSubmit}
                      >
                        {buttonText}
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Our Location</h4>
                      <p>
                        1008 West 14th Road <br></br>
                        Aurora, NE 68818 <br></br>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Our Phone Number</h4>
                      <p>
                        Greg Luthy <br></br>
                        <a href="tel:402-694-9002" onClick={logPhoneLinkClick}>(402) 694-9002</a> <br></br>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="big-map" id="contactUs2Map">
          <MapWrapper
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDXh5K4boKUJQtf8HqFHKdJCqCdmRlhCn4"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
