import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "010", "011", "012", "013", "014", "015", "016"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/maple-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our collection of Maple trees is wide ranging and sure to include the perfect specimen for everyone! These are the most popular landscape trees in North America, prized for their brilliant Fall foliage colors of red, yellow and orange. There are many different species of maple trees. All have a few common characteristics that make them stand out from other types of trees. Maple trees usually reach heights of 50 to 75 feet at maturity, although some remain smaller in home landscapes. They have spreading crowns with horizontal branch formations, and develop a compact and rounded canopy. These can make a beautiful centerpiece for your landscape or yard, are very hardy and can live for many years. Maple trees are a wonderful addition to any property.
            </p>
            <p>
                Types of Maple Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Brandywine</li>
                    <li>Celebration</li>
                    <li>Commemoration Sugar</li>
                    <li>Crimson King</li>
                    <li>Crimson Sentry</li>
                    <li>Emerald Lustre</li>
                    <li>Fall Fiesta Sugar</li>
                    <li>Green Mountain Sugar</li>
                    <li>Legacy Sugar</li>
                    <li>Majesty Sugar</li>
                    <li>October Glory</li>
                    <li>Red Sunset</li>
                    <li>Royal Red</li>
                    <li>Sienna Glen</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 9.5 and 13 feet, and have been planted in 20 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function MapleTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Maple Trees"
                        price="$195* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default MapleTrees;
