import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import Footer from "components/Footers/Footer.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import TreeCareHeader from "components/Headers/TreeCareHeader";

function TreeCare() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const formatFAQ = (question, answer) => {
    return (
      <>
        <h2 className="mt-5">{question}</h2>
        <p>{answer}</p>
      </>);
  }
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <TreeCareHeader />
        <div className="">
          <div className="blogs-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h3 className="title">Tree Care / FAQ </h3>
                </Col>
              </Row>
              <Row >
                <Col className="ml-auto mr-auto" md="10">
                  {formatFAQ('Can we plant the trees for you?', `Yes! While we no longer plant the potted trees, we are able to plant the taller and older spruce trees which are moved and planted with our professional Tree Spade service. Certain restrictions, such as a maximum distance of 25 miles from our farm, will apply. Please contact us for details.`)}
                  {formatFAQ('Do you guarantee the trees or have any type of warranty?', `For the 6 to 8 ft Spruce, yes we do. All larger planted Spruce come with a 1 Year Warranty. Please contact us for details. `)}
                  {formatFAQ('Do we provide specific instructions for taking care of the trees?', `Yes we do. We'll make sure you have all the information and instructions to properly care for your new trees.`)}
                  {formatFAQ('Do we help package your purchase and load your vehicle?', `You bet! No matter if you need help loading the trees into a truck or trailer, or packaging the branches up correctly for the drive home, we'll make sure you are all set!`)}
                  {formatFAQ('How long have we been in business?', `What started as a hobby in the 1990s, turned into a passionate small business as time went on. We are corn and soybean farmers as well, but nearly 20 years ago we began planting trees in bulk and our business has only grown from there. Over the past two decades, we've sold or moved thousands of trees all over central Nebraska.`)}
                  {formatFAQ('What additional services do we offer?', `We can also help design & plan how you'd like the trees planted on your property, assist with flagging the ground to prepare for the placement of the trees and also design & build irrigation drip lines to provide water to your newly planted trees.`)}
                  {formatFAQ('Are we open all year round?', `It depends on the weather, but we can generally provide trees and planting services from April to November. `)}                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default TreeCare;
