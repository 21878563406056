import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "008", "009", "010", "012"];

function getSrc(imgNumber) {
  return (`https://d11evpdhimy96f.cloudfront.net/colorado-blue-spruce/IMG_${imgNumber}.JPG`);
}

function getDescription() {
  return (
    <p>
      With silvery blue-green tones and colors all year round, a sleek pyramidal shape and no-nonsense care, the Colorado Blue Spruce tree checks all the boxes of a landscape must-have and will impress no matter where it's planted. They are resistant to wind and can tolerate dry periods well, as they boast a wider deeper root system than most Spruce. Colorado Blues do best in sunny locations, and can grow 12 to 18 inches per year. Whether you’re looking to add value to your landscape, an all-natural privacy screen or a windbreak, these Spruce are an ideal choice.
    </p>
  )
}

function getSizes() {
  return (
    <>
      <p>
        We offer several sizes, in both 5 gallon pots that are ready for pickup, and also larger specimens that can be moved and planted professionally on your property.
      </p>
      <p>The smaller Colorado Blue Spruce, in the 5 gal containers, range in size from approximately 2.5 to 3.5 feet. The larger Colorado Blue are 6 to 8 feet tall, and are planted in the ground in our nursery, requiring our professional tree moving service with our truck mounted spade, in order to be planted on your property. (Must be within 25 miles of our location)</p>
    </>
  )
}

function getDelivery() {
  return (<p>Our professional moving service of the larger 6 to 8 foot trees are bid on a job by job basis, and include a $1.25 per mile delivery charge. Please inquire about details.</p>)
}

const items = imgNumbers.map(i => {
  return {
    src: getSrc(i),
    altText: "",
    caption: ""
  }
});

function ColoradoBlueSpruce() {
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <WhiteNavbar />
      <div className="wrapper">
        <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
          <TreeDetails items={items}
            name="Colorado Blue Spruce"
            price="$39 per foot (planted by Tree Spade)"
            price2="$44 per tree (5 gal. potted type)"
            description={getDescription()}
            sizes={getSizes()}
            delivery={getDelivery()} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ColoradoBlueSpruce;
