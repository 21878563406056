import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import Footer from "components/Footers/Footer.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import ServicesHeader from "components/Headers/ServicesHeader";

function Services() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <ServicesHeader />
        <div className="">
          <div className="blogs-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h2 className="title">Services</h2>
                </Col>
              </Row>
              <Row >
                <Col className="ml-auto mr-auto" md="10">
                  <p>
                    <ul>
                      <li>On site potted tree selection available for pickup.</li>
                      <li>Professional tree planting service for larger conifers.</li>
                      <li>Designing and planning tree layout and flagging of your property for tree placement.</li>
                      <li>Designing and building irrigation systems for your property.</li>
                    </ul>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Services;
