import React, { Component } from "react";
import VirtualContainer from 'react-virtual-container'

// reactstrap components
import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
} from "reactstrap";

import Grid from "@material-ui/core/Grid";

import Footer from "components/Footers/Footer.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import GalleryHeader from "components/Headers/GalleryHeader";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'assets/css/gallery.css';

const galleryImageIds = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021"];
const galleryImages = galleryImageIds.map(i => {
    return {
        img: `https://d11evpdhimy96f.cloudfront.net/gallery/IMG_${i}.JPG`,
        cols: 1,
        title: ''
    }
});

export default class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = { photoIndex: 0, isOpen: false }

        document.body.classList.add("about-us");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

        this.images = [...galleryImages];

        this.handleClick = (idx) => {
            this.setState({ isOpen: true, photoIndex: idx });
        }
    }

    getGallery = () => {
        return (<div className="wrapper">
            <GalleryHeader />
            <div className="blogs-3">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="10">
                            <h2 className="title">Gallery</h2>
                        </Col>
                    </Row>
                    <Row style={{ minHeight: "100%" }}>
                        <Col className="ml-auto mr-auto" md="10">
                            <Grid container spacing={3}>
                                {this.images.map((tile, index) => (
                                    <Grid item xs={12} lg={4} sm={6} onClick={() => this.handleClick(index)} style={{ cursor: "pointer" }} key={index}>
                                        <Card className="card-background" style={{ backgroundImage: `url(${tile.img})` }}>
                                            <CardBody></CardBody>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>)
    }


    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <>
                <ScrollTransparentNavbar scrollThreshold={0} />
                <VirtualContainer>
                    {this.getGallery}
                </VirtualContainer>
                {isOpen && (
                    <Lightbox
                        style={{ zIndex: 1050 }}
                        mainSrc={this.images[photoIndex].img}
                        nextSrc={this.images[(photoIndex + 1) % this.images.length].img}
                        prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length].img}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.images.length - 1) % this.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.images.length,
                            })
                        }
                    />
                )}
            </>
        )
    }
}