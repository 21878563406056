import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import Footer from "components/Footers/Footer.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import AllTrees from "components/Trees/AllTrees";
import { Helmet } from 'react-helmet'

function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Helmet>
        <title>Luthy's Tree Farm - About Us</title>
        <meta name="description" content="A central Nebraska farm with a quality and affordable nursery option for coniferous, deciduous and fruit bearing trees." />
      </Helmet>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <AboutUsHeader />
        <div className="">
          <div className="blogs-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h2 className="title">About Us</h2>
                </Col>
              </Row>
              <Row >
                <Col className="ml-auto mr-auto" md="10">
                  <p>What began 30 years ago by purchasing a truck mounted tree spade to plant and move large trees around on our own farm, has now become a passion and family business.</p>

                  <p>We raise corn and soybeans on our farm outside of Aurora, while also operating a tree nursery consisting of a variety of different coniferous trees, with several thousand on site to choose from.</p>

                  <p>Whether it be providing smaller potted trees available for pickup or larger more mature trees that are professionally planted on your property, helping design and assist with tree placement or implementing an irrigation system, we offer all the services to get your tree from our nursery safely into the ground! </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="separator-line bg-info"></div>
          <AllTrees />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
