import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/peach-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                You'll find some of the very finest varieties of peaches amongst what we have to offer. Whether it's the Early Elberta and its tender sweet freestone fruits which can be enjoyed a variety of different ways, but are exceptionally delicious in cobblers and pies, or the Red Globe and its beautiful, large, firm and wonderfully sweet taste which makes it great for fresh eating and canning, as well as making ice cream and pies. Or the Redhaven, and its large, almost fuzzless skin over firm, creamy yellow flesh fruit, which is equally good for baking, canning or eating right from the tree, either selection is an excellent choice. They are heavy-bearing and disease-resistant, yielding large amounts of fresh delicious fruit. Each spring they bloom with an abundance of vibrant flowers, followed by its harvest season in mid summer.
            </p>
            <p>
                Types of Peach  Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Early Elberta</li>
                    <li>Elberta</li>
                    <li>Red Globe</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 7 and 9.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function PeachTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Peach Trees"
                        price="$85* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default PeachTrees;
