import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/cherry-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our collection of Cherry trees produce some of the largest and juiciest cherries there are!  Lapins have a mild, sweet flavor well suited for both fresh and cooked preparations, while Montmorency cherries have a medium-large, bright red fruit that has a firm yellow flesh; and a rich, tart flavor that bakers and jam makers love. They put on a brilliant show with billows of pink and white delicate flowers that cover the tree in late spring. Not only do these majestic trees add a beautiful flash of color to your yard every spring, but they also provide you with a nice crop of cherries mid summer. They display good disease resistance and tolerate late frost as well.
            </p>
            <p>
                Types of Cherry Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Danube</li>
                    <li>Jubileum</li>
                    <li>Lapins</li>
                    <li>Montmorency</li>
                    <li>Stella</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 6 and 7.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function CherryTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Cherry Trees"
                        price="$85* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default CherryTrees;
