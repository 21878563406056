/*!

=========================================================
* Now UI Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages
import AboutUs from "views/examples/AboutUs.js";
import ContactUs from "views/examples/ContactUs.js";


import ColoradoBlueSpruce from "components/Trees/ColoradoBlueSpruce";
import BlackHillsSpruce from "components/Trees/BlackHillsSpruce";
import NorwaySpruce from "components/Trees/NorwaySpruce";
import ChanticleerFloweringPear from "components/Trees/ChanticleerFloweringPear";
import Services from "views/Services";
import TreeCare from "views/TreeCare";
import GalleryPage from "views/Gallery";
import { createBrowserHistory } from 'history';
import AvailableTrees from "views/AvailableTrees";
import GA from 'utils/GoogleAnalytics';
import FloweringOrnamentalTrees from "components/Trees/FloweringOrnamentalTrees";
import MapleTrees from "components/Trees/MapleTrees";
import OakTrees from "components/Trees/OakTrees";
import LindenTrees from "components/Trees/LindenTrees";
import AppleTrees from "components/Trees/AppleTrees";
import ApricotTrees from "components/Trees/ApricotTrees";
import CherryTrees from "components/Trees/CherryTrees";
import NectarineTrees from "components/Trees/NectarineTrees";
import PeachTrees from "components/Trees/PeachTrees";
import PearTrees from "components/Trees/PearTrees";

const fixUrl = () => {
  const history = createBrowserHistory();

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  return true;
}

ReactDOM.render(
  fixUrl() &&
  <BrowserRouter>
    {GA.init() && <GA.RouteTracker />}
    <Switch>
      <Route path="/about-us" component={AboutUs} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/index" component={AboutUs} />
      <Route path="/services" component={Services} />
      <Route path="/colorado-blue-spruce" component={ColoradoBlueSpruce} />
      <Route path="/black-hills-spruce" component={BlackHillsSpruce} />
      <Route path="/norway-spruce" component={NorwaySpruce} />
      <Route path="/chanticleer-flowering-pear" component={ChanticleerFloweringPear} />
      <Route path="/tree-care" component={TreeCare} />
      <Route path="/gallery" component={GalleryPage} />
      <Route path="/available-trees" component={AvailableTrees} />
      <Route path="/flowering-ornamental-trees" component={FloweringOrnamentalTrees} />
      <Route path="/maple-trees" component={MapleTrees} />
      <Route path="/oak-trees" component={OakTrees} />
      <Route path="/linden-trees" component={LindenTrees} />
      <Route path="/apple-trees" component={AppleTrees} />
      <Route path="/apricot-trees" component={ApricotTrees} />
      <Route path="/cherry-trees" component={CherryTrees} />
      <Route path="/nectarine-trees" component={NectarineTrees} />
      <Route path="/peach-trees" component={PeachTrees} />
      <Route path="/pear-trees" component={PearTrees} />
      <Route path="/" component={AboutUs} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);