import React from "react";

// reactstrap components


import Footer from "components/Footers/Footer.js";
import AllTrees from "components/Trees/AllTrees";
import { Helmet } from 'react-helmet'

import WhiteNavbar from "components/Navbars/WhiteNavbar";
import AvailableTreesHeader from "components/Headers/AvailableTreesHeader";


function AvailableTrees() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Helmet>
        <title>Luthy's Tree Farm - Available Trees</title>
        <meta name="description" content="A central Nebraska farm with a quality and affordable nursery option for coniferous, deciduous and fruit bearing trees." />
      </Helmet>
      <WhiteNavbar />
      <div className="wrapper">
        <AvailableTreesHeader />
        <div className="">
          <AllTrees />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AvailableTrees;
