import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/linden/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Dark green on top and silvery underneath, Sterling Silver Linden's foliage delivers a fine display. On breezy days, you’ll look up and see its silver leaves dancing and shimmering in the sunlight, and in early summer, you’ll inhale the sweet scent of its cream colored flowers. Its fuzzy heart shaped leaves are highly ornamental and turn an outstanding gold in the fall. Sterling Silver Linden is a dense deciduous tree with a strong central leader and a distinctive and refined pyramidal form. It's a very disease and pest resistant specimen, and does best in full sunlight. As it matures, the lower branches can be strategically removed to create a high enough canopy to give plenty of room underneath. The Sterling Silver grows at a medium rate, and under ideal conditions can be expected to live for 70 years or more.
            </p>
            <p>
                Types of Linden Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Sterling Silver</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 9 and 11 feet, and have been planted in 20 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function LindenTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Linden Trees"
                        price="$150* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default LindenTrees;
