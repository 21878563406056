import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011"];

function getSrc(imgNumber) {
  return (`https://d11evpdhimy96f.cloudfront.net/chanticleer-pear/IMG_${imgNumber}.JPG`);
}



const items = imgNumbers.map(i => {
  return {
    src: getSrc(i),
    altText: "",
    caption: ""
  }
});

function getDescription() {
  return (
    <>
      <p>
        The Chanticleer flowering pear tree is one of the earliest blooming and most beautiful spring flowering trees. It adds ornamental value to any landscape, is easy to maintain and is resistant to both disease and drought conditions.
      </p>
      <p>
        Also known as the ornamental pear, the flowering pear tree is a deciduous tree that requires very little attention and is highly recommended. Most varieties of the white-flowering trees have rough bark and an oval or pyramidal shape which fits well in tight spaces, and can grow up to a height of 50 feet.
      </p>
      <p>
        Tiny white flowers appear in early April and, like snow, gently fall on green lawns. Glossy, green leaves turn yellow to scarlet red in October and November.
      </p>
    </>
  )
}

function getSizes() {
  return (
    <p>We offer Chanticleer Flowering Pear ranging in size from approximately 9 to 11 feet. These trees have been planted in 20 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree. These are ready for pickup at our nursery.</p>
  )
}

function ChanticleerFloweringPear() {
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <WhiteNavbar scrollThreshold={0} />
      <div className="wrapper">
        <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
          <TreeDetails
            items={items}
            name="Chanticleer Flowering Pear"
            price="$195* each"
            disclaimer
            description={getDescription()}
            sizes={getSizes()} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ChanticleerFloweringPear;
