import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/oak/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our collection of Oaks are some of the most beautiful and beloved species available.Native to the midwest, these are large deciduous trees with wide open crowns, well suited for landscaping.They are a great choice as a shade tree and for specimen plantings in parks, spacious yards, and other large areas.Whether you want the deep red autumn colors that give the Red Oak its name, the brilliant bright crimson of the Scarlet Oak that extends into winter, or the more traditional green to yellowish brown of the Bur Oak, each one is a fantastic choice.These are fast growing and long living varieties, with large acorns that attract birds and small mammals to your property. These oaks have a bark that develops into ridges and darkens over time, with a massive trunk and a large stately branch structure. They are very hardy, very disease resistant and well suited for our climate.
            </p>
            <p>
                Types of Oak Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Bur</li>
                    <li>Red</li>
                    <li>Scarlet</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 9 and 11.5 feet, and have been planted in 20 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function OakTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Oak Trees"
                        price="$195* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default OakTrees;
