import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/flowering-ornamental-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Crabapples and Eastern Redbuds make up our collection of flowering ornamental trees. Also known as "jewels of the landscape”, Crabapple trees remain attractive all year long. During spring, pink or red buds among glossy green leaves, open to aromatic blossoms in hues of white, pink or red. During fall, the foliage turns brilliant autumn colors. Likewise, and equally beautiful, Eastern Redbuds showcase clusters of tiny magenta buds that swell into rosy pink flowers in early spring before the leaves appear, with the long lasting blossoms on display. The buds appear to emerge right from the bark of twigs and branches, adorning the entire tree with miniature clusters of flowers.
            </p>
            <p>
                Types of Flowering Ornamental Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Eastern Redbud</li>
                    <li>Brandywine Crabapple</li>
                    <li>Harvest Gold Crabapple</li>
                    <li>Prairie Fire Crabapple</li>
                    <li>Profusion Crabapple</li>
                    <li>Snowdrift Crabapple</li>
                    <li>Spring Snow Crabapple</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (<p>Trees range in size between 7.5 and 10 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.</p>)
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function FloweringOrnamentalTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Flowering Ornamental Trees"
                        price="$125-135* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default FloweringOrnamentalTrees;
