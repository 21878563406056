import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "012"];

function getSrc(imgNumber) {
  return (`https://d11evpdhimy96f.cloudfront.net/norway-spruce/IMG_${imgNumber}.JPG`);
}

function getDescription() {
  return (
    <p>
      The fastest growing of all Spruce trees, the Norway Spruce is another excellent choice for your landscaping. This pyramidal conifer has large weeping branches with dark green foliage, and can quickly reach taller heights making it an ideal choice for a wind break, your yard or anywhere on your property that you're looking to provide some all natural privacy. These types of Spruce also thrive in cold climates and are long lived, plus resistant to wind and drought conditions.
    </p>
  )
}

function getSizes() {
  return (
    <>
      <p>
        We offer several sizes, in both 5 gallon pots that are ready for pickup, and also larger specimens that can be moved and planted professionally on your property.
      </p>
      <p>
        The smaller Norway Spruce, in the 5 gal containers, range in size from approximately 3 to 4 feet. The larger Norway Spruce are 6 to 8 feet tall, and are planted in the ground in our nursery, requiring our professional tree moving service with our truck mounted spade in order to be planted on your property. (Must be within 25 miles of our location)
      </p>
    </>
  )
}

function getDelivery() {
  return (
    <p>
      Our professional moving service of the larger 6 to 8 foot trees are bid on a job by job basis, and include a $1.25 per mile delivery charge. Please inquire about details.
    </p>
  )
}

const items = imgNumbers.map(i => {
  return {
    src: getSrc(i),
    altText: "",
    caption: ""
  }
});

function NorwaySpruce() {
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <WhiteNavbar scrollThreshold={0} />
      <div className="wrapper">
        <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
          <TreeDetails items={items} name="Norway Spruce" price="$39 per foot (planted by Tree Spade)" price2="$44 per tree (5 gal potted type)"
            description={getDescription()}
            sizes={getSizes()}
            delivery={getDelivery()} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default NorwaySpruce;
