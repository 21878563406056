import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/apricot-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                These apricot varieties offer vibrant, juicy fruits which have a red and bright orange flesh that seem to glow on the branch. The trees are adorned with profuse clusters of pink flowers in the spring, which are followed by medium sized, freestone fruits in mid summer. Whether it be a Robada with it's aromatic and pleasantly balanced flavor, or a deep, tangy taste of the Goldcot, both are perfect for fresh eating and canning. Both varieties can withstand cold winters, so you can depend on its hardiness, vigor and production consistency, even in areas with lower temperature climates.
            </p>
            <p>
                Types of Apricot Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Chinese</li>
                    <li>Wenatchee Moorpark</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 7.5 and 9.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function ApricotTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Apricot Trees"
                        price="$85* each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        delivery={getMeasurables()}
                        hideDelivery
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ApricotTrees;
