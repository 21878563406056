import React from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";

import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import { Carousel } from 'react-responsive-carousel';

require("react-responsive-carousel/lib/styles/carousel.min.css");

function TreeDetails(props) {

  document.body.scrollTop = 0;
  // carousel states and functions
  const [shouldScrollToTop, setShouldScrollToTop] = React.useState(true);

  if (shouldScrollToTop) {
    window.scrollTo(0, 0);
    setShouldScrollToTop(false);
  }

  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const showDelivery = props.delivery && !props.hideDelivery;

  return (
    <Container>
      <Row>
        <Col md="5">
          <Carousel
            style={{ "margin": "0" }}
            autoPlay={false}
            interval={false}
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
          >
            {props.items.map((item) => {
              return (
                <div>
                  <img src={item.src} alt="Tree" />
                </div>
              )
            })}
          </Carousel>
        </Col>
        <Col className="ml-auto mr-auto" md="6">
          <h2 className="title">{props.name}</h2>
          <h3 className="main-price">{props.price}
            {props.disclaimer &&
              <>
                <br />
                <b><small style={{ marginTop: -5 }}>*Price includes 1 Year Warranty</small></b>
              </>
            }
          </h3>
          {props.price2 &&
            <h3 className="main-price">{props.price2}</h3>
          }
          <div>
            <Button tag={Link} to="/contact-us" color="primary">Contact Us</Button>
          </div>
          <div
            aria-multiselectable={true}
            className="card-collapse"
            id="accordion"
            role="tablist"
          >
            {props.description &&
              <Card className="card-plain">
                <CardHeader id="headingOne" role="tab">
                  <a
                    aria-expanded={collapses.includes(1)}
                    data-parent="#accordion"
                    data-toggle="collapse"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(1);
                    }}
                  >
                    {props.descriptionTitleOverride || "Description"}{" "}
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </a>
                </CardHeader>
                <Collapse isOpen={collapses.includes(1)}>
                  <CardBody>
                    {props.description}
                  </CardBody>
                </Collapse>
              </Card>}
            {props.sizes &&
              <Card className="card-plain">
                <CardHeader id="headingTwo" role="tab">
                  <a
                    aria-expanded={collapses.includes(2)}
                    data-parent="#accordion"
                    data-toggle="collapse"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    {props.sizeTitleOverride || "Sizes Available"}{" "}
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </a>
                </CardHeader>
                <Collapse isOpen={collapses.includes(2)}>
                  <CardBody>
                    {props.sizes}
                  </CardBody>
                </Collapse>
              </Card>
            }
            {showDelivery &&
              <Card className="card-plain">
                <CardHeader id="headingThree" role="tab">
                  <a
                    aria-expanded={collapses.includes(3)}
                    data-parent="#accordion"
                    data-toggle="collapse"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      changeCollapse(3);
                    }}
                  >
                    {props.deliveryTitleOverride || "Delivery"}{" "}
                    <i className="now-ui-icons arrows-1_minimal-down"></i>
                  </a>
                </CardHeader>
                <Collapse isOpen={collapses.includes(3)}>
                  <CardBody>
                    {props.delivery}
                  </CardBody>
                </Collapse>
              </Card>
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}

TreeDetails.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string,
  price: PropTypes.string,
  price2: PropTypes.string,
  description: PropTypes.element,
  descriptionTitleOverride: PropTypes.string,
  sizes: PropTypes.element,
  sizeTitleOverride: PropTypes.string,
  delivery: PropTypes.element,
  deliveryTitleOverride: PropTypes.string,
  disclaimer: PropTypes.bool,
  hideDelivery: PropTypes.bool
}
export default TreeDetails;