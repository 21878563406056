import React from "react";


import Footer from "components/Footers/Footer";
import TreeDetails from "./TreeDetails";
import WhiteNavbar from "components/Navbars/WhiteNavbar";

const imgNumbers = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011"];

function getSrc(imgNumber) {
    return (`https://d11evpdhimy96f.cloudfront.net/apple-trees/IMG_${imgNumber}.JPG`);
}

function getAvailableTypes() {
    return (
        <>
            <p>
                Our collection of Apples trees are some of the most recognizable and beloved varieties in the orchard, from the mouth watering tart and tangy Granny Smith to the sweetness of the Honeycrisp, or the one of a kind flavor of the Braeburn. Whether you're snacking, baking, canning or juicing - we carry something for everyone! The spring months bring blossoms of pink and white hues, which make way for a bountiful harvest of fruit later in the year. These are hardy trees that can survive harsh winters, thrive in many different types of climates and can grow to heights in excess of 15 feet. They are generally very disease resistant, and enjoy as much sun as they can get!
            </p>
            <p>
                Types of Apple Trees for Sale:
            </p>
            <p>
                <ul>
                    <li>Braeburn</li>
                    <li>Gala</li>
                    <li>Granny Smith</li>
                    <li>Honeycrisp</li>
                    <li>Red McIntosh</li>
                </ul>
            </p>
        </>
    )
}

function getSizes() {
    return (
        <p>
            Trees range in size between 7.5 and 9.5 feet, and have been planted in 15 gallon containers using a high quality soil mix, therefore have excellent large healthy root bases. They have had plenty of room to grow and are not root bound. This is important to achieve maximum growth and maintain a healthy tree.
        </p>
    )
}

function getMeasurables() {
    return (<p>Insert table chart here</p>)
}

const items = imgNumbers.map(i => {
    return {
        src: getSrc(i),
        altText: "",
        caption: ""
    }
});

function AppleTrees() {
    React.useEffect(() => {
        document.body.classList.add("product-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("product-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <WhiteNavbar scrollThreshold={0} />
            <div className="wrapper">
                <div className="section" style={{ "paddingTop": "165px", paddingBottom: "45px" }}>
                    <TreeDetails
                        items={items}
                        name="Apple Trees"
                        price="$85 each"
                        disclaimer
                        description={getAvailableTypes()}
                        descriptionTitleOverride="Available Types"
                        sizes={getSizes()}
                        deliveryTitleOverride="Info and Measurables"
                        hideDelivery
                        delivery={getMeasurables()}
                    />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default AppleTrees;
